import { ispApi } from 'src/api';
import { AxiosRequestConfig } from 'axios';

import { TanstackTableSearchParamsAdapter } from '@itm/shared-frontend/lib/utils';

import {
  MembershipDetailDto,
  MemberSearchResponsePagedResponse,
  SearchMatchingStatus,
  SearchPensionValue,
  PDPStatus,
  MembershipAccountDetailsDto,
  EmploymentDto,
  MembershipPDPStatusResponse,
  MembershipMatchingDto,
  MembershipMatchingDetailsDto,
  AvailablePensionTypes,
  MembershipStatuses,
  EditPDPStatusDto,
  PossibleMatchResponseWithCount,
  MatchResolutionType,
  DeregisterMatchingDetailsDto,
  DemoteMatchingDetailsDto,
  ConfirmMembershipMatchingDto,
  EditMembershipMatchingDto,
  MembershipMatchingOverrideResponse,
  EditMatchingOverrideStatusModel,
  MembershipOutOfDateSearchResponsePagedResponse,
} from 'src/types';

export const getMembershipById = (membershipId: string, companyId: string, config: AxiosRequestConfig = {}) =>
  ispApi.get<MembershipDetailDto>(`/isp-internal/api/v1/memberships/${membershipId}/companies/${companyId}`, config);

export type MembershipListSearchParams = TanstackTableSearchParamsAdapter & {
  companyId: string;
  matchingStatus?: SearchMatchingStatus;
  pensionValue?: SearchPensionValue;
  pdpStatus?: PDPStatus;
  pensionRef?: string;
  possibleMatchCaseRef?: string;
  firstName?: string;
  surname?: string;
  nino?: string;
  dateOfBirth?: string;
  schemeId?: string;
};
export const getMembershipListSearch = (params: MembershipListSearchParams, config: AxiosRequestConfig = {}) =>
  ispApi.get<MemberSearchResponsePagedResponse>('/isp-internal/api/v1/memberships/search', { ...config, params });

export type MembershipParams = {
  membershipId: string;
  companyId: string;
};
export const getMembershipAccountDetailsById = (params: MembershipParams, config: AxiosRequestConfig = {}) =>
  ispApi.get<MembershipAccountDetailsDto>('/isp-internal/api/v1/memberships/account-details', { params, ...config });

export const updateMembershipDetail = (data: MembershipDetailDto) =>
  ispApi.post<string>('/isp-internal/api/v1/memberships', data);

export const getMembershipHistoryById = (membershipId: string, companyId: string, config: AxiosRequestConfig = {}) =>
  ispApi.get<EmploymentDto[]>(
    `/isp-internal/api/v1/memberships/${membershipId}/companies/${companyId}/employments`,
    config,
  );

export type MembershipPDPStatusParams = {
  companyId: string;
  membershipId: string;
};

export const getMembershipPDPStatusById = (params: MembershipPDPStatusParams, config: AxiosRequestConfig = {}) =>
  ispApi.get<MembershipPDPStatusResponse>('/isp-internal/api/v1/memberships/pdp-status', { ...config, params });

export const updateMembershipPDPStatus = (data: EditPDPStatusDto) =>
  ispApi.post<string>('/isp-internal/api/v1/memberships/edit-pdp-status', data);

export type MembershipMatchingOverrideStatusParams = {
  companyId: string;
  membershipId: string;
};

export const getMembershipMatchingOverrideStatus = (
  params: MembershipMatchingOverrideStatusParams,
  config: AxiosRequestConfig = {},
) =>
  ispApi.get<MembershipMatchingOverrideResponse>('/isp-internal/api/v1/memberships/matching-override-status', {
    ...config,
    params,
  });

export const updateMembershipMatchingOverrideStatus = (data: EditMatchingOverrideStatusModel) =>
  ispApi.post<string>('/isp-internal/api/v1/memberships/matching-override-status', data);

export const getMembershipMatchingDetailsList = (
  membershipId: string,
  companyId: string,
  config: AxiosRequestConfig = {},
) =>
  ispApi.get<MembershipMatchingDto[]>(
    `/isp-internal/api/v1/memberships/${membershipId}/companies/${companyId}/matching-details`,
    config,
  );

export const getMembershipMatchingDetailsById = (
  membershipMatchingId: string,
  companyId: string,
  config: AxiosRequestConfig = {},
) =>
  ispApi.get<MembershipMatchingDetailsDto>(
    `/isp-internal/api/v1/memberships/companies/${companyId}/matching-details/${membershipMatchingId}`,
    config,
  );

export const getMembershipAvailablePensionTypes = (companyId: string, config: AxiosRequestConfig = {}) =>
  ispApi.get<AvailablePensionTypes>(`/isp-internal/api/v1/memberships/companies/${companyId}/types`, config);

export const getMembershipPensionTypeAndStatus = (
  companyId: string,
  membershipId: string,
  config: AxiosRequestConfig = {},
) =>
  ispApi.get<MembershipStatuses>(
    `/isp-internal/api/v1/memberships/${membershipId}/companies/${companyId}/statuses`,
    config,
  );

export type PossibleMatchListSearchParams = {
  companyId: string;
  resolutionType?: MatchResolutionType;
  schemeId?: string;
  expireDateFrom?: string;
  expireDateTo?: string;
} & TanstackTableSearchParamsAdapter;

export const getPossibleMatchListSearch = (params: PossibleMatchListSearchParams, config: AxiosRequestConfig = {}) =>
  ispApi.get<PossibleMatchResponseWithCount>('/isp-internal/api/v1/memberships/possible-match/search', {
    ...config,
    params,
  });

export const updateMatchingStatusToDeregistered = (data: DeregisterMatchingDetailsDto) =>
  ispApi.patch<void>('/isp-internal/api/v1/memberships/matchingDetails/deregister', data);

export const updateMatchingStatusToDemoted = (data: DemoteMatchingDetailsDto) =>
  ispApi.patch<void>('/isp-internal/api/v1/memberships/matchingDetails/demote', data);

export const updateMatchingStatusToInvestigating = (data: DemoteMatchingDetailsDto) =>
  ispApi.patch<void>('/isp-internal/api/v1/memberships/matchingDetails/investigating', data);

export const confirmMembershipMatching = (data: ConfirmMembershipMatchingDto) =>
  ispApi.post<string>('/isp-internal/api/v1/memberships/confirm-membership-matching', data);

export const editMembershipMatching = (data: EditMembershipMatchingDto) =>
  ispApi.post<string>('/isp-internal/api/v1/memberships/edit-membership-matching', data);

export type OutOfDatePensionsListSearchParams = {
  companyId: string;
} & TanstackTableSearchParamsAdapter;

export const getOutOfDatePensionsListSearch = (
  params: PossibleMatchListSearchParams,
  config: AxiosRequestConfig = {},
) =>
  ispApi.get<MembershipOutOfDateSearchResponsePagedResponse>('/isp-internal/api/v1/memberships/out-of-date', {
    ...config,
    params,
  });
