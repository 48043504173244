import { AxiosRequestConfig } from 'axios';
import { createSlice, createAsyncThunk, PayloadAction, miniSerializeError, SerializedError } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';

import { ServerErrorAdapter } from '@itm/shared-frontend/lib/utils';

import { getMembershipById } from 'src/api/isp/membership';
import { getCountryCodes } from 'src/api/isp/adminContact';

import { MembershipDetailDto, ServerError } from 'src/types';

type MembershipState = {
  isLoading: boolean;
  isView: boolean | null;
  serverErrors: string[];
  selectedMembership: MembershipDetailDto | null;
  countryCodes: {
    isLoading: boolean;
    countryCodeList: string[];
    serverErrorMessages: string[];
  };
};

const serializeError = (e: unknown): SerializedError => {
  const message = new ServerErrorAdapter(e as ServerError).combine().join('\n  ');
  return miniSerializeError({ message });
};

type FetchMembershipByIdActionParams = {
  membershipId: string;
  companyId: string;
  config: AxiosRequestConfig<any>;
};

export const fetchMembershipByIdAction = createAsyncThunk(
  'membership/fetchMembershipByIdAction',
  async ({ membershipId, companyId, config }: FetchMembershipByIdActionParams) => {
    const res = await getMembershipById(membershipId, companyId, config);
    return res.data;
  },
  { serializeError },
);

export const fetchCountryCodeList = createAsyncThunk(
  'adminContact/fetchCountryCodeList',
  async (companyId: string) => {
    const res = await getCountryCodes(companyId);
    return res.data;
  },
  { serializeError },
);

const initialState: MembershipState = {
  isLoading: true,
  isView: null,
  selectedMembership: null,
  serverErrors: [],
  countryCodes: {
    isLoading: false,
    countryCodeList: [],
    serverErrorMessages: [],
  },
};

const membershipSlice = createSlice({
  name: 'membership',
  initialState,
  reducers: {
    resetMembership(state: MembershipState) {
      Object.assign(state, initialState);
    },
    setIsViewMembership(state: MembershipState, action: PayloadAction<boolean | null>) {
      state.isView = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMembershipByIdAction.pending, (state: MembershipState) => {
      state.isLoading = true;
      state.serverErrors = [];
    });
    builder.addCase(fetchMembershipByIdAction.fulfilled, (state: MembershipState, action) => {
      state.selectedMembership = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchMembershipByIdAction.rejected, (state: MembershipState, action) => {
      state.serverErrors = action.error.message ? [action.error.message] : [];
      state.selectedMembership = null;
      state.isView = null;
      state.isLoading = false;
    });

    builder.addCase(fetchCountryCodeList.pending, (state: MembershipState) => {
      state.countryCodes.isLoading = true;
    });
    builder.addCase(fetchCountryCodeList.fulfilled, (state: MembershipState, action) => {
      state.countryCodes.isLoading = false;
      state.countryCodes.countryCodeList = action.payload;
    });
    builder.addCase(fetchCountryCodeList.rejected, (state: MembershipState, action) => {
      state.countryCodes.isLoading = false;
      state.countryCodes.serverErrorMessages = action.error.message ? [action.error.message] : [];
    });

    // Cleanup
    builder.addCase(resetStore, (state: MembershipState) => {
      Object.assign(state, initialState);
    });
  },
});

export const { setIsViewMembership, resetMembership } = membershipSlice.actions;

export default membershipSlice.reducer;
